/* Standard Rules */
@import "primeng/resources/themes/bootstrap4-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
}

clr-vertical-nav {
  width: 175px;
}

/* Tools */

.nav-subheading {
  margin: 0;
  margin-top: 8px;
  margin-left: 14px;
  font-size: 16px;
  font-weight: bold;
}

.clear-padding {
  padding: 0;
}

.clear-padding-x {
  padding-left: 0;
  padding-right: 0;
}

.clear-padding-y {
  margin-top: 0;
  margin-bottom: 0;
}

.clear-margin {
  margin: 0;
}

.clear-margin-x {
  padding-left: 0;
  padding-right: 0;
}

.clear-margin-y {
  margin-top: 0;
  margin-bottom: 0;
}

.flex-x {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.form {
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
}

/* Fonts */

.font-bold {
  font-weight: 500;
}

.font-center {
  text-align: center;
}

/* Buttons */

.minibtn-primary {
  background-color: #44697d;
  color: white;
}

.minibtn-primary:hover {
  background-color: #00a9e0;
  color: white;
}

.minibtn-danger {
  background-color: #ea6359;
  color: white;
}

.minibtn-danger:hover {
  color: #da291c;
}

/* Sidebar */

.nav-link.active {
  color: #091858;
}

.sidebar-link {
  color: #091858;
  font-size: 16px;
  cursor: pointer;
}

.sidebar-link:hover {
  background-color: #00a9e0;
  color: white !important;
}

.ngx-file-drop__drop-zone {
  border-radius: 0 !important;
  border-color: var(--clr-btn-primary-border-color, #0072a3);
  height: 150px !important;
  overflow: hidden;
}

.ngx-file-drop__content {
  height: 150px !important;
  overflow: hidden;
}

/*.nw-editor {*/
/*  height: calc(100vh - 290px) !important;*/
/*}*/


.statColumn {
  vertical-align: top;
}
.statHeader {
  padding-top: 23px;
  font-weight: bold;
  font-size: 12pt;
}
.statValue {
  font-size: 11pt;
}

.statAlertContent {
  max-height: 400px;
  overflow-y: auto;
}

.icon-test {
  display: block;
  background: transparent url(/assets/images/image.svg) no-repeat 50%;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  margin: 0;
  opacity: .5;
  padding: 0;
  width: 30px !important;
  height: 30px !important;
}

.mat-card-header-text {
    margin: 0  !important;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
}

.mat-drawer-inner-container {
    overflow-x: hidden !important;
    padding-right: 16px;
}
