/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "@angular/material/theming";

$typography: mat.define-typography-config(
    $font-family: "Open Sans",
    $headline: mat.define-typography-level(24px, 36px, 700),
);

@include mat.core($typography);

$newmont-blue: (
    50 : #e1e3e7,
    100 : #b5b9c3,
    200 : #848a9b,
    300 : #525b72,
    400 : #2d3854,
    500 : #081536,
    600 : #071230,
    700 : #060f29,
    800 : #040c22,
    900 : #020616,
    A100 : #5668ff,
    A200 : #233aff,
    A400 : #001aef,
    A700 : #0017d5,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$newmont-gold: (
    50 : #fff5e3,
    100 : #fee7b9,
    200 : #fed78b,
    300 : #fdc75d,
    400 : #fcbb3a,
    500 : #fcaf17,
    600 : #fca814,
    700 : #fb9f11,
    800 : #fb960d,
    900 : #fa8607,
    A100 : #ffffff,
    A200 : #fff7ef,
    A400 : #ffdcbc,
    A700 : #ffcfa2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$primary: mat.define-palette($newmont-blue, 600, 100, 900);
$accent: mat.define-palette($newmont-gold, 600, 100, 900);

$warn: mat.define-palette(mat.$red-palette, 600, 100, 900);
$good: mat.define-palette(mat.$green-palette, 600, 100, 900);

$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-component-themes($theme);
